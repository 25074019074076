<template>
<div>
    <NavBottom :checkNum="5" />

    <div class="page" v-if="!!model">
        <div class="top_wrp">
            <img style="width:100%" src="../assets/bannerBg.jpg" />
            <div class="top_info">
                <div class="top_info_in">
                    <div class="order_id">
                        订单号：{{model.Apply_CertNo}}
                    </div>
                </div>
            </div>
        </div>

        <div class="itm-wrp">
            <div class="tit">【微公益】 保险理赔报销服务</div>
            <div class="time-date">
                {{model.Apply_Create}}
            </div>
            <div class="eCord">
                <div class="enum"> 票号: {{model.Apply_Code}} </div>
                <img class="ecordImg" :src="domin+model.Apply_Image" />

                <div class="tipCont">
                    二维码使用说明：<br />
                    1、为了维护你的权益，请不要把二维码分享给他人。<br />
                    2、在服务人员完成收件后；需要扫描本二维码完成服务环节。
                </div>
            </div>
        </div>
        <div class="itm-wrp">
            <div class="line-wrp">
                <span>申请人: {{model.Apply_Name}}</span><span>电话: {{model.Apply_Phone}}</span>
            </div>
            <div class="one-line-wrp">
                <span>地址: {{model.Apply_District+" "+model.Apply_Address}}</span>
            </div>

            <div class="line-wrp">
                <span>服务专员: {{courier.Courier_Name}}</span><span>电话: {{courier.Courier_Phone}}</span>
            </div>
        </div>
        <div class="itm-wrp">
            <div class="tip-tit">温馨提示:</div>
            <div class="tipCont">
                1、本服务为公益活动，全程不收取任何费用。
            </div>
            <div class="tipCont">
                2、本服务仅为委托办理提交；对于是否可以报销，以保险公司的最终赔付为准。因保险公司拒赔或其他原因造成的不可赔付的结果，有申请人或被保险人及其监护人自行与保险公司协商；服务人员与本机构不承担在此期间造成的任何损失。
            </div>
            <div class="tipCont">
                3、服务人员在服务过程中的言论仅代表个人对于产品的认知和理解，不代表保险公司和本服务所属机构的观点。
            </div>
            <div class="tipCont">
                4、本机构和服务人员保证在收件和提交过程中材料的完整性、正确性。
            </div>
            <div class="tipCont">
                5、申请人、被保险人及其监护人需保证材料的真实性。
            </div>
        </div>
    </div>
</div>

</template>

<script>
export default {
    data() {
        return {
            domin: this.domin,
            model: null,
            courier: null,
            array: ['医保报销办理', '残疾证委托代办', '长期护理险申报', '特殊门诊申报', '医保政策解答咨询', '医疗保险报销咨询', '其他便民服务咨询', '学生保险报销理赔'],
        }
    },
    created() {
        let that = this

        this.wpost({
            url: '/Ajax/Product/getapplymodel',
            data: {
                id: this.$route.params.id
            },
            succ: function (data) {
                console.log("data:", data)
                that.model = data.model
                that.courier = data.courier
            }
        })
    },
    methods: {
        statusNow() {
            var str = "审核中..." + this.model.Apply_FinishTime + "<br>";
            if (this.model.Apply_verify < 7) {
                if (this.model.Apply_Verify > 0) {
                    str += "审核完成..." + this.model.Apply_VerifyTime + "<br>"
                }
                if (this.model.Apply_Verify > 1) {
                    str += "上门联系中..." + this.model.Apply_ContactTime + "<br>"
                }

                if (this.model.Apply_Verify > 2) {
                    str += "收件完成..." + this.model.Apply_ReceiveTime + "<br>"
                }
                if (this.model.Apply_Verify > 3) {
                    str += "提交核算中..." + this.model.Apply_ClainStart + "<br>"
                }
                if (this.model.Apply_Verify > 4) {
                    str += "理赔完成..." + this.model.Apply_ClainEnd + "<br>";
                    str += "赔付金额..." + this.model.Apply_ClainMoney + "<br>";
                    str += "发票金额..." + this.model.Apply_InvoiceMoney + "<br>";
                    str += "自理金额..." + this.model.Apply_SelfMoney + "<br>"
                }
            } else if (this.model.Apply_Verify == 8) {
                str += "拒绝理赔... <br>";
                str += "拒绝理由..." + this.model.Apply_RejectReason + "<br>"
            } else if (this.model.Apply_Verify == 9) {
                str += "理赔退款...<br>";
                str += "退款理由.." + this.model.Apply_RejectReason + "<br>"
            } else if (this.model.Apply_Verify == 10) {
                str += "终止服务 " + this.model.Apply_FinishTime + "<br>"
            }
            return str
        },
    }
}
</script>

<style scoped>

.adPic {
    width: 100%;
}

.adWrp {
    border-top: 1px solid #eee;
    margin: 10px 20px;
}

.jfpng {
    position: absolute;
    transform: rotateZ(45deg);
    top: 8%;
    right: 6%;
    height: 40px;
    width: 80px;
}

.top_wrp,
.order_info {
    position: relative;
}

.top_wrp image {
    width: 100%;
}

.moreInsurePerson {
    margin: 12px;
    border: 1px solid;
    border-color: rgba(249, 122, 10, 0.5);
    border-radius: 6px;
}

.top_info {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    color: #fff;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.top_info_in {
    width: 92%;
    margin: 0 auto;
}

.tit {
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.order_id {
    margin-top: 6px;
    font-size: 13px;
}

.order_info {
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-left: none;
    border-right: none;
    margin-top: 12px;
}

.rows {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 12px 22px;
    font-size: 13px;
}

.rows label {
    display: inline-block;
    width: 35%;
    font-weight: bold;
    color: #333;
}

.rows span {
    color: #666;
}

.tip-tit {
    font-size: 15px;
    margin: 8px 0;
}

.tipCont {
    font-size: 13px;
    line-height: 2;
    color: #6A6A6A;
    text-align: left;
}

.ticket-tip {
    margin-top: 6px;
    font-size: 13px;
}

.line-wrp {
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 2;
    font-size: 14px;
}

.one-line-wrp {
    line-height: 1.2;
    font-size: 13px;
}

.line-wrp span {
    display: block;
    width: 50%;
}

.eCord {
    margin: 22px auto;
    text-align: center;
}

.ecordImg {
    width: 50%;
}

.date-time {
    font-size: 16px;
    color: #F39800
}

.enum {
    line-height: 2;
    font-size: 12px;
    color: #6A6A6A
}

.time-date,
.adres,
.poi {
    padding: 8px 0;
    font-size: 12px;
    color: #6A6A6A
}

.poi {
    color: #F39800;
    font-size: 13px;
    font-weight: bold;
}

.adres {
    width: calc(100% - 66px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.adrress-wrp {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.tit {
    color: #F39800;
    font-size: 17px;
    font-weight: bold;
}

.itm-wrp {
    margin: 12px;
    padding: 12px;
    background:#fff;
    border-radius: 6px;
    box-shadow: 0 0 6px #DCDCDC;
}
</style>
